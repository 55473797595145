import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'studentfilter',
    pure: false
})

export class StudentFilterPipe implements PipeTransform {
    transform(items: any[], searchText: string, fieldName: string, fieldName1: string): any[] {
        // return empty array if array is falsy
    if (!items) { return []; }

    // return the original array if search text is empty
    // if (!searchText) { return items; }
    if (!searchText)
      searchText = '';
    
    // convert the searchText to lower case
    searchText = searchText.toLowerCase();

    // retrun the filtered array
    return items.filter(item => {
      if (item && item[fieldName]) {
        return item[fieldName].toLowerCase().includes(searchText) || item[fieldName1].toLowerCase().includes(searchText);
      }
      return false;
    });
   }
}
