import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ReportRequest } from './models/ReportRequest';

@Injectable({ providedIn: 'root' })
export class ReportService {
    public config = environment;

    constructor(private http: HttpClient) { }

    ssrsReport(reportRequest: ReportRequest) {
        return this.http.post<any>(`${this.config.apiUrl}/reports/ssrs`, reportRequest);
    }

}