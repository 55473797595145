import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { City } from "../../models/city/City";
import { BuyerInfo } from "../../models/ticket/BuyerInfo";
import { PurchaseInfo } from "../../models/ticket/PurchaseInfo";
import { Purchase } from "../../models/ticket/Purchase";
import { OTPDetail } from "./models/OTPDetail";
import { PaymentSuccess } from "../../models/ticket/PaymentSuccess";

@Injectable({ providedIn: 'root' })
export class OTPService {
    public config = environment;

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any>(`${this.config.apiUrl}/city`);
    }

    checkOTP(request: OTPDetail) {
        return this.http.post<any>(`${this.config.apiUrl}/otp/checkOTP`, request);
    }

    createOTP(request: BuyerInfo) {
        return this.http.post<any>(`${this.config.apiUrl}/otp/createOTP`, request);
    }

    reserve(request: Purchase) {
        return this.http.post<any>(`${this.config.apiUrl}/ticket/ticketReserve`, request);
    }

    purchaseSuccess(request: PaymentSuccess) {
        return this.http.post<any>(`${this.config.apiUrl}/ticket/purchaseSuccess`, request);
    }

    purchaseFailed(request: PaymentSuccess) {
        return this.http.post<any>(`${this.config.apiUrl}/ticket/purchaseFailed`, request);
    }

    update(city: City) {
        return this.http.put(`${this.config.apiUrl}/city/${city.cityid}`, city);
    }

    delete(id: number) {
        return this.http.delete(`${this.config.apiUrl}/city/${id}`);
    }    
}
