import { INavData } from '@coreui/angular';

export const limitedNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer'
    // badge: {
    //   variant: 'info',
    //   text: 'NEW'
    // }
  },
  {
    name: 'Administration',
    url: '/admin',
    icon: 'icon-settings',
    children: [
      {
        name: 'Absent Reason',
        url: '/admin/absent-reasons',
        icon: 'icon-people'
      },
      {
        name: 'Court',
        url: '/admin/courts',
        icon: 'icon-people'
      },
      {
        name: 'Institution',
        url: '/admin/institutions',
        icon: 'icon-people'
      },
      {
        name: 'Location',
        url: '/admin/locations',
        icon: 'icon-people'
      },
      {
        name: 'Quarter',
        url: '/admin/quarters',
        icon: 'icon-calendar'
      },
      {
        name: 'Session Type',
        url: '/admin/session-types',
        icon: 'icon-list'
      },  
      {
        name: 'Tag',
        url: '/admin/tags',
        icon: 'icon-people'
      },
    ]
  },
  {
    name: 'Scheduling',
    url: '/schedule',
    icon: 'icon-clock',
    children: [
      {
        name: 'Session Calendar',
        url: '/schedule/session-calendar',
        icon: 'icon-calendar'
      },
      {
        name: 'Session Review',
        url: '/schedule/session-review',
        icon: 'icon-check'
      },
      {
        name: 'Reporting',
        url: '/schedule/reports',
        icon: 'icon-doc'
      }
    ]
  },
  {
    name: 'Security',
    url: '/security',
    icon: 'icon-clock',
    children: [
      {
        name: 'Group(s)',
        url: '/security/groups',
        icon: 'icon-calendar'
      },
      {
        name: 'Group User(s)',
        url: '/security/group-user',
        icon: 'icon-calendar'
      }
    ]
  },
  {
    name: 'Communication',
    url: '/communication',
    icon: 'icon-paper-plane',
    children: [
      {
        name: 'Financial',
        url: '/communication/emails',
        icon: 'icon-speech'
      },
      {
        name: 'General',
        url: '/communication/general',
        icon: 'icon-speech'
      }
    ]
  },
  {
    name: 'Financial',
    url: '/financial',
    icon: 'icon-credit-card',
    children: [
      {
        name: 'Reporting',
        url: '/financial/reports',
        icon: 'icon-doc'
      }
    ]
  },
  {
    name: 'General',
    url: '/general',
    icon: 'icon-credit-card',
    children: [
      {
        name: 'Reporting',
        url: '/general/reports',
        icon: 'icon-doc'
      }
    ]
  }
];
