import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { BuyerInfo } from '../../../models/ticket/BuyerInfo';
import { EventGroup } from '../../../models/ticket/EventGroup';
import { TicketingEvent } from '../../../models/ticket/ticketingevent';
import { User } from '../../../services/admin/user/models/User';
import { AuthService } from '../../../services/authentication/auth.service';
import { OTPService } from '../../../services/otp/otp.service';
import { TicketService } from '../../../services/ticket.service';
import { Purchase } from '../../../models/ticket/Purchase';
import { PurchaseInfo } from '../../../models/ticket/PurchaseInfo';
import { Ticket } from '../../../models/ticket/Ticket';
import { OTPDetail } from '../../../services/otp/models/OTPDetail';
import { PaymentSuccess } from '../../../models/ticket/PaymentSuccess';
import { ReportRequest } from '../../../services/reporting/models/ReportRequest';
import { DomSanitizer } from '@angular/platform-browser';
import { ReportService } from '../../../services/reporting/report.service';
import { PurchaseList } from '../../../models/ticket/PurchaseList';
import { Transaction } from '../../../services/transaction/models/Transaction';
import { YocoRedirectRequest } from '../../../models/payment/YocoRedirectRequest';
import { YocoService } from '../../../services/yoco.service';
import { PaymentState } from '../../../models/payment/PaymentState';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-event-display',
  templateUrl: './event-display.component.html',
  styleUrls: ['./event-display.component.scss']
})
export class EventDisplayComponent implements OnInit {

  currentUser: User;
  currentEvent: Number;
  submittedb = false;
  submittedt = false;
  submittedotp = false;
  // submitted = false;
  error: string;
  loading = false;
  loadingotp = false;
  loadingevent = false;
  eventgroup: Array<EventGroup> = [];
  purchaselist: Array<PurchaseList> = [];
  mainevent:EventGroup;
  buyerinfoForm: FormGroup;
  otpForm: FormGroup;
  step = 0;
  buyerdetail = true;
  ticketdetail = true;
  paymentdetail = true;
  ticketingevent:TicketingEvent;
  billingTotal = 0;
  tickettotal = 1;
  otpvalid = false;
  otpgenerated = false;
  otpcorrect = true;
  sendotp = false;
  emailreadonly = false;
  otp = 0;
  state: string = "list";

  purchaseid = -1;
  paymentid = '-1';

  pdfContent: any;

  mediaBreakpoint: string;
  public innerHeight: any;
  public innerWidth: any;

  yocoredirectrequest: YocoRedirectRequest;
  billingtotalloading = false;


  // yoco

  loadingYoco = false;
  paymentInline:any;

  @ViewChild('1') one: ElementRef;
  @ViewChild('2') two: ElementRef;
  @ViewChild('3') three: ElementRef;
  @ViewChild('4') four: ElementRef;
  @ViewChild('5') five: ElementRef;

  constructor(
    private router: Router, 
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private ticketService: TicketService,
    private otpService: OTPService,
    private yocoService: YocoService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private reportService: ReportService,
    private sanitizer: DomSanitizer) { 
      this.authService.currentUser.subscribe(x => this.currentUser = x);
      this.authService.eventid.subscribe(x => this.currentEvent = x);
      console.log(this.currentEvent);
    }

  ngOnInit(): void {
    this.authService.mediaBreakpoint.subscribe((data)=> { 
      this.mediaBreakpoint = data 
      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
    });

    this.buyerinfoForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$")])],
      mobile: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*')])]
    });
    this.otpForm = this.formBuilder.group({
      otp1: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]')])],
      otp2: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]')])],
      otp3: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]')])],
      otp4: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]')])],
      otp: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*')])]
    });
    
    if (localStorage.getItem('_id_ticket') != undefined)
      this.status();
    else
      this.fetchOpenTicketingEvent();
  }
  get f() { return this.buyerinfoForm.controls; }
  get g() { return this.otpForm.controls; }

  fetchOpenTicketingEvent(): void {
    this.loadingevent = true;
    this.ticketService.getOpenTicketingEvent()
      .pipe(first())
      .subscribe(
          data => {
              //console.log('fetchBillingEntities data', data);
              if (!data.success) {
                this.loadingevent = false;
                this.error = data.message;
              }
              else {
                this.eventgroup = data.data;
                this.eventgroup.forEach(e => {
                  e.imagesrc = `data:image/png;base64,${e.image}`;
                  
                });
                console.log(this.eventgroup)
                this.loadingevent = false;
              }
          },
          error => {
              this.error = error;
              this.loadingevent = false;
          });
  }

  generateReport() {
    this.loading = true;
    var reportRequest = new ReportRequest();
    reportRequest.reportname = 'tickets';
    reportRequest.parameters = "purchaseid="+this.purchaseid+"|preview="+'false';
    reportRequest.format = "pdf";

    this.reportService.ssrsReport(reportRequest)
         .pipe(first())
         .subscribe(
             data => {
              if(reportRequest.format=='pdf'){
                this.pdfContent = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + data.data);
              }
               this.loading = false;
             },
             error => {
               console.log('report error', error);
                 this.loading = false;
             });
  }

  ticketTotal(e){
    if(e == 'plus')
      this.tickettotal++
    else{
      if(this.tickettotal > 0)
        this.tickettotal--
    }
    this.billingTotal = this.ticketingevent.price * this.tickettotal;
  }

  setStep(index: number) {
    this.step = index;
  }

  correctEmail(e: boolean){
    this.emailreadonly = e;
    if(e)
      if(!this.otpgenerated){
        this.sendotp = true;
        this.createOTP();
      }
      this.setFocus('1',null);
    
  }

  setFocus(nextElement, event) {
    // console.log(event.key)
    if(event == null)
      this.one.nativeElement.focus();
    if (event.key >= '0' && event.key <= '9'){
      if((this.g['otp1'].value == null || this.g['otp1'].value == '' || this.g['otp1'].value == undefined)
        && (this.g['otp2'].value == null || this.g['otp2'].value == '' || this.g['otp2'].value == undefined)
        && (this.g['otp3'].value == null || this.g['otp3'].value == '' || this.g['otp3'].value == undefined)
        && (this.g['otp4'].value == null || this.g['otp4'].value == '' || this.g['otp4'].value == undefined) ){
          // console.log(this.g.value)
        }
        else
          this.close(true);

          switch (nextElement) {
            case "1": this.one.nativeElement.focus();
             break;
            case "2":  (this.g['otp1'].value == null || this.g['otp1'].value == '' || this.g['otp1'].value == undefined) ? this.one.nativeElement.focus() : this.two.nativeElement.focus() ;
             break;
            case "3":  (this.g['otp2'].value == null || this.g['otp2'].value == '' || this.g['otp2'].value == undefined) ? this.two.nativeElement.focus() : this.three.nativeElement.focus() ;
             break;
            case "4":  (this.g['otp3'].value == null || this.g['otp3'].value == '' || this.g['otp3'].value == undefined) ? this.three.nativeElement.focus() : this.four.nativeElement.focus() ;
             break;
            case "5": (this.g['otp4'].value == null || this.g['otp4'].value == '' || this.g['otp4'].value == undefined) ? this.four.nativeElement.focus() : this.four.nativeElement.focus() ;
             break;
          }
    }
  }

  

  createOTP(){
    this.loading = true;
    var bi = new BuyerInfo();
    bi.email = this.f['email'].value;
    bi.mobile = this.f['mobile'].value;
    bi.firstname = this.f['firstname'].value;
    bi.lastname = this.f['lastname'].value;
    this.otpService.createOTP(bi)
      .pipe(first())
      .subscribe(
          data => {
              //console.log('fetchBillingEntities data', data);
              if (!data.success) {
                this.loading = false;
                this.error = data.message;
              }
              else {
                document.getElementById('1').focus();
                this.otp = data.data.otp1;
                this.loading = false;
                this.otpgenerated = true;
              }
          },
          error => {
              this.error = error;
              this.loading = false;
          });
  }

  checkOTP(selection: boolean){
    this.loadingotp = true;
    // this.g['otp'].setValue(this.g['otp1'].value + this.g['otp2'].value + this.g['otp3'].value + this.g['otp4'].value)
    var otpdetail = new OTPDetail();
    otpdetail.email = this.f['email'].value;
    // this.g['otp'].setValue(this.g['otp1'].value.toString() + this.g['otp2'].value.toString() + this.g['otp3'].value.toString() + this.g['otp4'].value.toString())
    otpdetail.otp = this.g['otp'].value;
    // console.log(this.g['otp'].value)
    this.otpService.checkOTP(otpdetail)
      .pipe(first())
      .subscribe(
          data => {
              //console.log('fetchBillingEntities data', data);
              this.otpcorrect = data.success
              if (!data.success) {
                this.loadingotp = false;

                this.loading = false;
                this.error = data.message;
                this.toastr.warning(data.message, 'OTP [validation]', { timeOut: 5000, positionClass: 'toast-bottom-right' });
              }
              else {
                this.loadingotp = false;

                // this.eventgroup = data.data;
                this.otpvalid = selection;
                this.loading = false;
                this.toastr.success('Success.', 'OTP', {timeOut: 500, positionClass: 'toast-bottom-right'});
                if(this.state =='history')
                  this.fetchPurchaseHistory();
                this.step++
              }
          },
          error => {
              this.error = error;
              this.toastr.error('Error: ' + error.error.message, 'OTP [validate]', { timeOut: 5000, positionClass: 'toast-bottom-right' });
              this.loading = false;
          });
  }

  close(selection: boolean) {
    this.g['otp'].setValue(this.g['otp1'].value.toString() + this.g['otp2'].value.toString() + this.g['otp3'].value.toString() + this.g['otp4'].value.toString())
    if(selection){
      this.submittedotp = true;
      if (this.otpForm.invalid) {
        return;
      }
      this.checkOTP(selection);
    }
    else{
      this.createOTP();
      this.submittedotp = false;
      this.otpvalid = selection;
    }

  }

  nextStep() {
    if(this.step == 0){
      this.submittedb = true;
      if (this.buyerinfoForm.invalid) {
        return;
      }
      if(!this.otpvalid){
        this.step++;
        // this.buyerdetail = false;
      }
      else
        this.step = 2;

    }
    else if(this.step == 1){
      if(this.otpvalid)
        this.step++;
    }
    else if(this.step == 2){
      if(this.tickettotal == 0)
        return;

      this.f['firstname'].setValue(this.f['firstname'].value);
      this.f['lastname'].setValue(this.f['lastname'].value);
      this.f['email'].setValue(this.f['email'].value);
      this.f['mobile'].setValue(this.f['mobile'].value);
        this.step++;
    }
    // else if(this.step == 3){
    //   this.reserveTicket();
    //   this.step++;
    // }
    else
      this.step++;
  }

  prevStep() {
    if(this.step == 2){
      this.step = 0;
    }
    else
    this.step--;
  }

  nextStepHistory() {
    
    if(this.step == 1){
      this.submittedb = true;
        if (this.f['email'].invalid) {
          return;
        }
      if(!this.otpvalid){
        this.step++;
      }
      else
        this.step = 3;
    }
    else if(this.step == 2)
    {
      if(this.otpvalid)
        this.step++;
      this.fetchPurchaseHistory();
    }
    else{
      if(this.step == 1){
        this.submittedb = true;
        if (this.f['email'].invalid) {
          return;
        }
      }
      this.step++;
    }
  }

  prevStepHistory() {
    if(this.step == 3)
      this.step = 1;
    else
      this.step--;
  }

  login(){
    this.router.navigate(['/login']);
  }

  fetchPurchaseHistory(){
    this.loading = true;
    var bi = new BuyerInfo();
    bi.email = this.f['email'].value;
    bi.mobile = this.f['mobile'].value;
    bi.firstname = this.f['firstname'].value;
    bi.lastname = this.f['lastname'].value;
    this.ticketService.purchaseHistory(bi)
      .pipe(first())
      .subscribe(
          data => {
              console.log('purchaseHistory data', data);
              if (!data.success) {
                this.loading = false;
                this.error = data.message;
              }
              else {
                this.purchaselist = data.data;
                console.log(this.purchaselist);
                this.loading = false;
              }
          },
          error => {
              this.error = error;
              this.loading = false;
          });
  }

  purchaseHistory(){
    this.state='history';
    this.step = 1;
  }

  fetchTicket(e){
    this.purchaseid = e
    this.state='tickethistory';
    this.generateReport();
  }

  backHistory(){
    this.state='history';
    this.pdfContent = '';
  }

  back(){
    this.fetchOpenTicketingEvent();
    this.submittedb = false;
    this.submittedt = false;
    this.submittedotp = false;
    this.state='list';
    this.billingTotal = 0;
    this.tickettotal = 1;
    this.otpvalid = false;
    this.otpgenerated = false;
    this.otpcorrect = true;
    this.sendotp = false;
    this.emailreadonly = false;
    this.otp = 0;
    this.step = 0;

    this.g['otp1'].setValue('');
    this.g['otp2'].setValue('');
    this.g['otp3'].setValue('');
    this.g['otp4'].setValue('');
    this.g['otp'].setValue('');

    this.f['firstname'].setValue('');
    this.f['lastname'].setValue('');
    this.f['email'].setValue('');
    this.f['mobile'].setValue('');

  }

  otpNext(e:string){
    document.getElementById(e).focus();
    // console.log(e)

  }

  ticketClicked(te,eg){
    console.log(te)

    if(te.seatcount > te.ticketcount){
      this.state = 'purchase';
      this.ticketingevent = te;
      this.mainevent = eg;
      this.billingTotal = this.ticketingevent.price * this.tickettotal;
    }
    else
    this.toastr.warning('Tickets sold out', 'Tickets', { timeOut: 5000, positionClass: 'toast-bottom-right' });

  }

  purchaseSuccess() {
    this.loading = true;
    var ps = new PaymentSuccess();
    ps.paymentid = this.paymentid;
    ps.purchaseid = this.purchaseid;

    console.log(ps);
    this.otpService.purchaseSuccess(ps)
      .pipe(first())
      .subscribe(
          data => {
              //console.log('fetchBillingEntities data', data);
              if (!data.success) {
                this.loading = false;
                this.error = data.message;
                this.toastr.error('Error: ' + data.message, 'Ticket [purchase]', { timeOut: 5000, positionClass: 'toast-bottom-right' });
              }
              else {
                this.eventgroup = data.data;
                this.loading = false;
                this.otpgenerated = true;
                this.toastr.success('Success.', 'Ticket payment', {timeOut: 500, positionClass: 'toast-bottom-right'});
                this.generateReport();
                this.state='ticket';
                // this.back();
              }
          },
          error => {
            this.toastr.error('Error: ' + error.error.message, 'Ticket [purchase]', { timeOut: 5000, positionClass: 'toast-bottom-right' });
              this.error = error;
              this.loading = false;
          });
  }

  purchaseFailed() {
    this.loading = true;
    var ps = new PaymentSuccess();
    ps.paymentid = this.paymentid;
    ps.purchaseid = this.purchaseid;

    console.log(ps);
    this.otpService.purchaseFailed(ps)
      .pipe(first())
      .subscribe(
          data => {
              //console.log('fetchBillingEntities data', data);
              if (!data.success) {
                this.loading = false;
                this.error = data.message;
                this.toastr.error('Error: ' + data.message, 'Ticket [purchase]', { timeOut: 5000, positionClass: 'toast-bottom-right' });
              }
              else {
                this.eventgroup = data.data;
                this.loading = false;
                this.otpgenerated = true;
                this.toastr.success('Success.', 'Ticket payment', {timeOut: 500, positionClass: 'toast-bottom-right'});
                this.back();
              }
          },
          error => {
            this.toastr.error('Error: ' + error.error.message, 'Ticket [purchase]', { timeOut: 5000, positionClass: 'toast-bottom-right' });
              this.error = error;
              this.loading = false;
          });
  }

  status() {
    console.log('checkoutStatus start');
    var response = this.yocoService.checkoutStatus(localStorage.getItem('_id_ticket')).subscribe(data => { 
      console.log('checkoutStatus', data.data)
      if (data.data['status'] === 'completed'){
        this.paymentSuccess(data.data['status'],localStorage.getItem('_id_ticket'));
        localStorage.removeItem('_id_ticket');
        this.toastr.success('Success.', 'Ticket payment', {timeOut: 500, positionClass: 'toast-bottom-right'});
        this.state='ticket';
      }
      else if(data.data['status'] === 'processing'){

      }
      else{
        this.toastr.error('Failed.', 'Ticket payment', {timeOut: 3000, positionClass: 'toast-bottom-right'});
        this.paymentFailed(data.data['status'],localStorage.getItem('_id_ticket'));
        localStorage.removeItem('_id_ticket');

      }
      this.fetchOpenTicketingEvent();
    },
    error => {
      console.log('error', error);
    });
    
  }

  paymentSuccess(status :string, reference: string) {
    var state = new PaymentState
    state.from = 'purchase';
    state.status = status;
    state.reference = reference;
    var response = this.yocoService.paymentSuccess(state).subscribe(data => { 
      if(!data.success){

      }
      else{
        // this.fetchPayAmount();
        this.purchaseid = data.data.purchaseinfo.purchaseid;
        this.generateReport();
      }
    },
    error => {
        console.log('error', error);
    });



  }

  paymentFailed(status :string, reference: string) {
    var state = new PaymentState
    state.from = 'purchase';
    state.status = status;
    state.reference = reference;
    var response = this.yocoService.paymentFailed(state).subscribe(data => { 
      if(!data.success){

      }
      else{
        // this.fetchPayAmount();
        // this.purchaseid = data.data.purchaseinfo.purchaseid;
        // this.generateReport();
      }
    },
    error => {
        console.log('error', error);
    });



  }

  reserveTicket(event:Event) {
    this.loading = true;
    var purchase = new Purchase();
    purchase.purchaseinfo = new PurchaseInfo();
    purchase.purchaseinfo.email = this.f['email'].value;
    purchase.purchaseinfo.mobile = this.f['mobile'].value;
    purchase.purchaseinfo.firstname = this.f['firstname'].value;
    purchase.purchaseinfo.lastname = this.f['lastname'].value;
    purchase.purchaseinfo.ticketsbought = this.tickettotal;
    purchase.purchaseinfo.transactionid = -1;
    purchase.purchaseinfo.ticketingeventid = this.ticketingevent.ticketingeventid;

    purchase.ticket = new Ticket();
    // purchase.ticket.ticketingeventid = this.ticketingevent.ticketingeventid;
    this.otpService.reserve(purchase)
      .pipe(first())
      .subscribe(
          data => {
              //console.log('fetchBillingEntities data', data);
              if (!data.success) {
                this.loading = false;
                this.error = data.message;
                this.back();
                this.toastr.warning(data.message, 'Ticket [reservation]', { timeOut: 5000, positionClass: 'toast-bottom-right' });
              }
              else {

                this.eventgroup = data.data;
                this.loading = false;
                this.otpgenerated = true;
                this.purchaseid = data.data.purchaseinfo.purchaseid;
                this.pay(event);
                // this.setupYoco();
                // this.back();
              }
          },
          error => {
              this.error = error;
              this.loading = false;
          });
  }

  pay(event:Event) {
    if(this.billingTotal < 0)
      return
    this.billingtotalloading = true;
    this.yocoredirectrequest = new YocoRedirectRequest;
    this.yocoredirectrequest.amount = this.billingTotal*100;
    this.yocoredirectrequest.currency = 'ZAR';

    if(environment.env == 'prod'){
      this.yocoredirectrequest.cancelUrl = 'https://eventer.org.za/#/event-display';
      this.yocoredirectrequest.successUrl = 'https://eventer.org.za/#/event-display';
      }
  
      if(environment.env == 'test'){
        this.yocoredirectrequest.cancelUrl = 'http://197.242.155.92:50006/#/event-display';
        this.yocoredirectrequest.successUrl = 'http://197.242.155.92:50006/#/event-display';
      }
      if(environment.env == 'dev'){
      this.yocoredirectrequest.cancelUrl = 'https://localhost:4200/#/event-display';
      this.yocoredirectrequest.successUrl = 'https://localhost:4200/#/event-display';
      }

    this.yocoredirectrequest.from = 'purchase';
    this.yocoredirectrequest.userid = -1;
    this.yocoredirectrequest.purchaseid = this.purchaseid;

    // this.yocoredirectrequest.eventid = this.event.eventid;
    // console.log(this.yocoredirectrequest);
    var response = this.yocoService.createCheckout(this.yocoredirectrequest).subscribe(data => { 
      console.log('data', data.data)
      console.log('id', data.data['id'])
      localStorage.setItem('_id_ticket', data.data['id']);
      console.log('redirectUrl', data.data['redirectUrl'])
      window.location.href = data.data['redirectUrl'];
    },
    error => {
        console.log('error', error);
    });
  }

  setupYoco() {
    var sdk = new (window as any).YocoSDK({
      publicKey: 'pk_test_64241662dVKljOk960e4' // pk_live_d480cd49V4j6GVA89634  pk_test_64241662dVKljOk960e4
    });
    // Create a new dropin form instance
    var transaction = new Transaction();
    transaction.paymentid = this.paymentid;
    transaction.purchaseid = this.purchaseid;
    transaction.amount = this.billingTotal;

    console.log('transaction',transaction)

    this.paymentInline = sdk.inline({
      layout: 'basic',
      amountInCents: Math.ceil(this.billingTotal * 100),
      currency: 'ZAR',
      metadata: { from: 'purchase', transaction: transaction}
    });
    // this ID matches the id of the element we created earlier.
    this.paymentInline.mount('#card-frame');
    this.loadingYoco = false;
  }

  payYoco() {
    this.loadingYoco = true;
    this.loading = true;
    let page = this;
    // this.reserveTicket();
    this.paymentInline.createToken().then(function (result) {
      if (result.error) {
        page.loadingYoco = false;
        console.error("error occured: " + result.error.message);
        page.toastr.error(result.error.message, 'Error (Yoco)', { timeOut: 5000, positionClass: 'toast-bottom-right' });
      } else {
        const token = result;
        page.authService.yocoPay(token.id, Math.ceil(page.billingTotal * 100))
          .subscribe(
            data => {
              //console.log('SUCCESS', data);
              if (data.success) {
                // Success happens here
                // page.router.navigate(['/payment-success']);
                console.log(data);
                page.paymentid = data.data.Id;
                page.loadingYoco = false;
                page.loading = false;
                page.otpgenerated = true;
                page.toastr.success('Success.', 'Ticket payment', {timeOut: 500, positionClass: 'toast-bottom-right'});
                page.generateReport();
                page.state='ticket';
                // page.purchaseSuccess();

              } else {
                page.loadingYoco = false;
                // page.purchaseFailed();
                page.toastr.error(data.message, 'Error (Yoco Api)', { timeOut: 5000, positionClass: 'toast-bottom-right' });
              }
            },
            error => {
              console.error(error.message);
              page.loadingYoco = false;
              page.toastr.error(error.message, 'Error (Yoco Api)', { timeOut: 5000, positionClass: 'toast-bottom-right' });
            }
          )
      }
    }).catch(function (error) {
      page.loadingYoco = false;
      page.toastr.error(error, 'Error (Yoco)', { timeOut: 5000, positionClass: 'toast-bottom-right' });
      console.error("error occured: " + error);
    });
  }
}
