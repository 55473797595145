import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { YocoRedirectRequest } from "../models/payment/YocoRedirectRequest";
import { PaymentState } from "../models/payment/PaymentState";

@Injectable({ providedIn: 'root' })
export class YocoService {
    public config = environment;

    constructor(private http: HttpClient) { }

    createCheckout(request:YocoRedirectRequest) {
        // var request = { 'amount': 200, 'currency': 'ZAR', 'cancelUrl': 'https://localhost:4200/#/dashboard', 'successUrl': 'https://localhost:4200/#/dashboard'  }
        return this.http.post<any>(`${this.config.apiUrl}/yoco/checkouts`, request);
    }

    checkoutStatus(_id: string) {
        var request = { 'id': _id }
        return this.http.post<any>(`${this.config.apiUrl}/yoco/checkoutStatus`, request);
    }

    

    paymentSuccess(request:PaymentState) {
        // var request = { 'amount': 200, 'currency': 'ZAR', 'cancelUrl': 'https://localhost:4200/#/dashboard', 'successUrl': 'https://localhost:4200/#/dashboard'  }
        return this.http.put<any>(`${this.config.apiUrl}/yoco/paymentSuccess`, request);
    }

    paymentFailed(request:PaymentState) {
        // var request = { 'amount': 200, 'currency': 'ZAR', 'cancelUrl': 'https://localhost:4200/#/dashboard', 'successUrl': 'https://localhost:4200/#/dashboard'  }
        return this.http.put<any>(`${this.config.apiUrl}/yoco/paymentFailed`, request);
    }
}
