import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { District } from '../models/district/District';

@Injectable({ providedIn: 'root' })
export class DistrictService {
    public config = environment;

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any>(`${this.config.apiUrl}/district`);
    }

    getAllByProvince(provinceid: number) {
        return this.http.get<any>(`${this.config.apiUrl}/district/province/${provinceid}`);
    }

    getById(id: number) {
        return this.http.get<any>(`${this.config.apiUrl}/district/${id}`);
    }   

	insert(district: District) {
        return this.http.post<any>(`${this.config.apiUrl}/district`, district);
    }

    update(district: District) {
        return this.http.put(`${this.config.apiUrl}/district/${district.districtid}`, district);
    }

    delete(id: number) {
        return this.http.delete(`${this.config.apiUrl}/district/${id}`);
    } 
}
