import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/authentication/auth.service';
import { ConfirmationComponent } from '../../shared/dialog/confirmation/confirmation.component';
import { DistrictService } from '../../services/district.service';
import { District } from '../../models/district/District';
import { City } from '../../models/city/City';
import { CityService } from '../../services/city.service';
import { ProvinceService } from '../../services/province.service';
import { Province } from '../../models/province/Province';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent {
  orgSelected: boolean;
  selectionMade: boolean = false;
  submitted = false;
  registerForm: FormGroup;
  error: string;
  loading: boolean;
  districts: Array<District>;
  provinces: Array<Province>;
  towns: Array<City>;

  constructor(
	private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private districtService: DistrictService,
    private provinceService: ProvinceService,
    private cityService: CityService,
	private toastr: ToastrService,
	public dialog: MatDialog) { }

  selectOption(isOrg: boolean) {
	  this.selectionMade = true;
	  this.orgSelected = isOrg;
	  if (isOrg) {
		  this.f.organisation.enable();
		  this.f.mnemonic.enable();
	  }
	  else {
		  this.f.organisation.disable();
		  this.f.mnemonic.disable();
	  }
  }

  ngOnInit() {
	this.registerForm = this.formBuilder.group({
		organisation: ['', Validators.required],
		mnemonic: ['', [Validators.maxLength(3), Validators.required]],
		firstname: ['', Validators.required],
		lastname: ['', Validators.required],
		email: ['', [Validators.email, Validators.required]],
		mobilenumber: ['', Validators.required],
		address1: ['', Validators.required],
		address2: [''],
		town: ['', Validators.required],
		postcode: ['', Validators.required],
		province: ['', Validators.required],
		district: ['', Validators.required]
	  });
	  this.fetchDistricts();
	  this.fetchProvinces();
	  this.fetchCities();
  }

//   ngAfterViewInit() {
// 	this.selectOption(true);
//   }

  get f() { return this.registerForm.controls; }

  goLogin() {
	  this.router.navigate(['/login']);
  }

  fetchDistricts() {
	this.districtService.getAll()
	.pipe(first())
	.subscribe(
		data => {
			if (!data.success) {
				this.loading = false;
				this.authService.error = data.message;
				}
				else {
				this.districts = data.data;
				this.loading = false;
				}
			},
			error => {
				this.error = error;
				this.loading = false;
			}
	);
  }

  fetchProvinces() {
	this.provinceService.getAll()
	.pipe(first())
	.subscribe(
		data => {
			if (!data.success) {
				this.loading = false;
				this.authService.error = data.message;
				}
				else {
				this.provinces = data.data;
				this.loading = false;
				}
			},
			error => {
				this.error = error;
				this.loading = false;
			}
	);
  }

  fetchCities() {
	this.cityService.getAll()
	.pipe(first())
	.subscribe(
		data => {
			if (!data.success) {
				this.loading = false;
				this.authService.error = data.message;
				}
				else {
				this.towns = data.data;
				this.loading = false;
				}
			},
			error => {
				this.error = error;
				this.loading = false;
			}
	);
  }

  onSubmit() {
	  this.submitted = true;
	  this.error = null;

	  console.log('this.loginForm.invalid', this.registerForm.invalid);
      console.log('this.loginForm', this.registerForm);
	  console.log(this.f)

	  if (this.registerForm.invalid) {
		return;
	}
	this.loading = true;
	this.authService.register(this.f.organisation.value, this.f.mnemonic.value, this.f.firstname.value, this.f.lastname.value, this.f.email.value, this.f.mobilenumber.value, this.f.address1.value, this.f.address2.value, this.f.town.value, this.f.postcode.value, this.f.district.value)
            .subscribe(
                data => {
                    console.log('data', data);
                    if (!data.success) {
                      this.error = data.message;
					  this.loading = false;
                    }
                    else {
                      //this.toastr.success('Succesfully Registered, you will get an email with your password', 'Success', {timeOut: 5000, positionClass: 'toast-bottom-right'});
					  	var dialogRef = this.dialog.open(ConfirmationComponent, {
							minWidth: 300,
							data: {
								displayText: `An email with a temporary password will be sent to ${this.f.email.value} shortly.`,
								titleText: "Success",
								btnType: "btn-primary",
								showCancel: false
							}
						});
				
						dialogRef.afterClosed().subscribe(data => {
							if (data.data) {
								this.router.navigate(['/login']);
							}
						});
                    }
                },
                error => {
					this.toastr.error(`Something went wrong, ${this.f.email.value} could not be registered`, 'Error', {timeOut: 5000, positionClass: 'toast-bottom-right'});
					this.error = error;
					this.loading = false;
                });
	
  }

}
