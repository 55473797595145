import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'userfilter',
    pure: false
})

export class UserFilterPipe implements PipeTransform {
    transform(items: any[], searchText: string, fieldName: string, fieldName1: string): any[] {
        // return empty array if array is falsy
    if (!items) { return []; }

    if (!searchText)
      searchText = '';
    
    // convert the searchText to lower case
    searchText = searchText.toLowerCase().replace('ö', 'o');

    // retrun the filtered array
    return items.filter(item => {
      if (item && item[fieldName]) {
        return item[fieldName].toLowerCase().replace('ö', 'o').includes(searchText) 
                || (item[fieldName1] == null || item[fieldName1].trim().length == 0 || item[fieldName1].toLowerCase().replace('ö', 'o').includes(searchText));
      }
      return false;
    });
   }
}
