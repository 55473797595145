import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { City } from '../models/city/City';

@Injectable({ providedIn: 'root' })
export class CityService {
    public config = environment;

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any>(`${this.config.apiUrl}/city`);
    }

    getById(id: number) {
        return this.http.get(`${this.config.apiUrl}/city/${id}`);
    }

    insert(city: City) {
        return this.http.post<any>(`${this.config.apiUrl}/city`, city);
    }

    update(city: City) {
        return this.http.put(`${this.config.apiUrl}/city/${city.cityid}`, city);
    }

    delete(id: number) {
        return this.http.delete(`${this.config.apiUrl}/city/${id}`);
    }    
}
