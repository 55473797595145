import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private _isLoading = new BehaviorSubject(false);

  constructor() { }

  get isLoading() {
    return this._isLoading.asObservable();
  }

  setIsLoading(value: boolean) {
    this._isLoading.next(value);
  }
}