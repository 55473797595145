import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer'
    // badge: {
    //   variant: 'info',
    //   text: 'NEW'
    // }
  },
  {
    name: 'Administration',
    url: '/admin',
    icon: 'icon-settings',
    children: [
      // {
      //   name: 'Dashboard',
      //   url: '/admin',
      //   icon: 'icon-speedometer'
      // },
      // {
      //   name: 'Absent Reason',
      //   url: '/admin/absent-reasons',
      //   icon: 'icon-people'
      // },
      // {
      //   name: 'Billing Entity',
      //   url: '/admin/billing-entities',
      //   icon: 'icon-star'
      // },
      // {
      //   name: 'Court',
      //   url: '/admin/courts',
      //   icon: 'icon-people'
      // },
      // {
      //   name: 'Institution',
      //   url: '/admin/institutions',
      //   icon: 'icon-people'
      // },
      // {
      //   name: 'Location',
      //   url: '/admin/locations',
      //   icon: 'icon-people'
      // },
      {
        name: 'Age Groups',
        url: '/admin/age-groups',
        icon: 'icon-people'
      },
      {
        name: 'Events',
        url: '/admin/events',
        icon: 'icon-event'
        // attributes: { hidden: true }
      },
      {
        name: 'Levels',
        url: '/admin/levels',
        icon: 'icon-layers'
      },
      {
        name: 'Organisations-Users',
        url: '/admin/organisation-user',
        icon: 'icon-organization'
      },
      {
        name: 'Students',
        url: '/admin/students',
        icon: 'icon-user'
        // attributes: { hidden: true }
      },
      {
        name: 'Types',
        url: '/admin/types',
        icon: 'icon-people'
      },
      {
        name: 'Disciplines',
        url: '/admin/disciplines',
        icon: 'icon-briefcase'
      },
      {
        name: 'Sections',
        url: '/admin/sections',
        icon: 'icon-pie-chart'
      },
	  {
        name: 'Districts',
        url: '/admin/districts',
        icon: 'icon-home'
      },
      {
        name: 'Ticketing Event',
        url: '/admin/ticketing-event',
        icon: 'icon-tag'
      },
      {
        name: 'Receipt',
        url: '/admin/Receipt',
        icon: 'icon-note'
      },
      {
        name: 'Transaction',
        url: '/admin/Transaction',
        icon: 'icon-wallet'
      },
      // {
      //   name: 'Quarter',
      //   url: '/admin/quarters',
      //   icon: 'icon-calendar'
      // },
      // {
      //   name: 'Session Type',
      //   url: '/admin/session-types',
      //   icon: 'icon-list'
      // },  
      // {
      //   name: 'Tag',
      //   url: '/admin/tags',
      //   icon: 'icon-people'
      // },
      // {
      //   name: 'User',
      //   url: '/admin/users',
      //   icon: 'icon-people'
      // },
    ]
  },
  // {
  //   name: 'Scheduling',
  //   url: '/schedule',
  //   icon: 'icon-clock',
  //   children: [
  //     {
  //       name: 'Session(s)',
  //       url: '/schedule/sessions',
  //       icon: 'icon-calendar'
  //     },
  //     {
  //       name: 'Session Calendar',
  //       url: '/schedule/session-calendar',
  //       icon: 'icon-calendar'
  //     },
  //     {
  //       name: 'Session Review',
  //       url: '/schedule/session-review',
  //       icon: 'icon-check'
  //     },
  //     {
  //       name: 'Reporting',
  //       url: '/schedule/reports',
  //       icon: 'icon-doc'
  //     }
  //   ]
  // },
  {
    name: 'Security',
    url: '/security',
    icon: 'icon-clock',
    children: [
      {
        name: 'Group(s)',
        url: '/security/groups',
        icon: 'icon-calendar'
      },
      {
        name: 'Group User(s)',
        url: '/security/group-user',
        icon: 'icon-calendar'
      }
    ]
  },
  // {
  //   name: 'Communication',
  //   url: '/communication',
  //   icon: 'icon-paper-plane',
  //   children: [
  //     {
  //       name: 'Financial',
  //       url: '/communication/emails',
  //       icon: 'icon-speech'
  //     },
  //     {
  //       name: 'General',
  //       url: '/communication/general',
  //       icon: 'icon-speech'
  //     }
  //   ]
  // },
  {
    name: 'Financial',
    url: '/financial',
    icon: 'icon-credit-card',
    children: [
      {
        name: 'Overview',
        url: '/financial/generals',
        icon: 'icon-speech'
      },{
        name: 'Billing',
        url: '/financial/billings',
        icon: 'icon-speech'
      },
      {
        name: 'Receipts',
        url: '/financial/receipts',
        icon: 'icon-speech'
      },
      {
        name: 'Reporting',
        url: '/financial/reports',
        icon: 'icon-doc'
      }
    ]
  },
  // {
  //   name: 'General',
  //   url: '/general',
  //   icon: 'icon-credit-card',
  //   children: [
  //     {
  //       name: 'Reporting',
  //       url: '/general/reports',
  //       icon: 'icon-doc'
  //     }
  //   ]
  // },
  // {
  //   title: true,
  //   name: 'Theme'
  // },
  // {
  //   name: 'Colors',
  //   url: '/theme/colors',
  //   icon: 'icon-drop'
  // },
  // {
  //   name: 'Typography',
  //   url: '/theme/typography',
  //   icon: 'icon-pencil'
  // },
  // {
  //   title: true,
  //   name: 'Components'
  // },
  // {
  //   name: 'Base',
  //   url: '/base',
  //   icon: 'icon-puzzle',
  //   children: [
  //     {
  //       name: 'Cards',
  //       url: '/base/cards',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Carousels',
  //       url: '/base/carousels',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Collapses',
  //       url: '/base/collapses',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Forms',
  //       url: '/base/forms',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Navbars',
  //       url: '/base/navbars',
  //       icon: 'icon-puzzle'

  //     },
  //     {
  //       name: 'Pagination',
  //       url: '/base/paginations',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Popovers',
  //       url: '/base/popovers',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Progress',
  //       url: '/base/progress',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Switches',
  //       url: '/base/switches',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tables',
  //       url: '/base/tables',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tabs',
  //       url: '/base/tabs',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tooltips',
  //       url: '/base/tooltips',
  //       icon: 'icon-puzzle'
  //     }
  //   ]
  // },
  // {
  //   name: 'Buttons',
  //   url: '/buttons',
  //   icon: 'icon-cursor',
  //   children: [
  //     {
  //       name: 'Buttons',
  //       url: '/buttons/buttons',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Dropdowns',
  //       url: '/buttons/dropdowns',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Brand Buttons',
  //       url: '/buttons/brand-buttons',
  //       icon: 'icon-cursor'
  //     }
  //   ]
  // },
  // {
  //   name: 'Charts',
  //   url: '/charts',
  //   icon: 'icon-pie-chart'
  // },
  // {
  //   name: 'Icons',
  //   url: '/icons',
  //   icon: 'icon-star',
  //   children: [
  //     {
  //       name: 'CoreUI Icons',
  //       url: '/icons/coreui-icons',
  //       icon: 'icon-star',
  //       badge: {
  //         variant: 'success',
  //         text: 'NEW'
  //       }
  //     },
  //     {
  //       name: 'Flags',
  //       url: '/icons/flags',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Font Awesome',
  //       url: '/icons/font-awesome',
  //       icon: 'icon-star',
  //       badge: {
  //         variant: 'secondary',
  //         text: '4.7'
  //       }
  //     },
  //     {
  //       name: 'Simple Line Icons',
  //       url: '/icons/simple-line-icons',
  //       icon: 'icon-star'
  //     }
  //   ]
  // },
  // {
  //   name: 'Notifications',
  //   url: '/notifications',
  //   icon: 'icon-bell',
  //   children: [
  //     {
  //       name: 'Alerts',
  //       url: '/notifications/alerts',
  //       icon: 'icon-bell'
  //     },
  //     {
  //       name: 'Badges',
  //       url: '/notifications/badges',
  //       icon: 'icon-bell'
  //     },
  //     {
  //       name: 'Modals',
  //       url: '/notifications/modals',
  //       icon: 'icon-bell'
  //     }
  //   ]
  // },
  // {
  //   name: 'Widgets',
  //   url: '/widgets',
  //   icon: 'icon-calculator',
  //   badge: {
  //     variant: 'info',
  //     text: 'NEW'
  //   }
  // },
  // {
  //   divider: true
  // },
  // {
  //   title: true,
  //   name: 'Extras',
  // },
  // {
  //   name: 'Pages',
  //   url: '/pages',
  //   icon: 'icon-star',
  //   children: [
  //     {
  //       name: 'Login',
  //       url: '/login',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Register',
  //       url: '/register',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Error 404',
  //       url: '/404',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Error 500',
  //       url: '/500',
  //       icon: 'icon-star'
  //     }
  //   ]
  // },
  // {
  //   name: 'Disabled',
  //   url: '/dashboard',
  //   icon: 'icon-ban',
  //   badge: {
  //     variant: 'secondary',
  //     text: 'NEW'
  //   },
  //   attributes: { disabled: true },
  // },
  // {
  //   name: 'Download CoreUI',
  //   url: 'http://coreui.io/angular/',
  //   icon: 'icon-cloud-download',
  //   class: 'mt-auto',
  //   variant: 'success',
  //   attributes: { target: '_blank', rel: 'noopener' }
  // },
  // {
  //   name: 'Try CoreUI PRO',
  //   url: 'http://coreui.io/pro/angular/',
  //   icon: 'icon-layers',
  //   variant: 'danger',
  //   attributes: { target: '_blank', rel: 'noopener' }
  // }
];
