import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Province } from '../models/province/Province';

@Injectable({ providedIn: 'root' })
export class ProvinceService {
    public config = environment;

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any>(`${this.config.apiUrl}/province`);
    }

    // getAllByCategory(categoryid: number) {
    //     return this.http.get<any>(`${this.config.apiUrl}/agegroups/category/${categoryid}`);
    // }

    // getById(id: number) {
    //     return this.http.get(`${this.config.apiUrl}/agegroups/${id}`);
    // }   
}
