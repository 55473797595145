import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

import { UsersByEntityRequest } from './models/UsersByEntityRequest';
import { User } from './models/User';
import { AlertsRequest } from './models/AlertsRequest';
import { UserGroupsRequest } from './models/UserGroupsRequest';
import { UserGroupsSaveRequest } from './models/UserGroupsSaveRequest';
import { UsersByGroupRequest } from './models/UsersByGroupRequest';
import { UsersByGroupSaveRequest } from './models/UsersByGroupSaveRequest';
import { UserValidateRequest } from './models/UserValidateRequest';
import { UsersByEventRequest } from './models/UsersByEventRequest';

@Injectable({ providedIn: 'root' })
export class UserService {
    public config = environment;

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`${this.config.apiUrl}/users`);
    }

    getById(id: number) {
        return this.http.get<any>(`${this.config.apiUrl}/users/${id}`);
    }

    insert(user: User) {
        return this.http.post<any>(`${this.config.apiUrl}/users`, user);
    }

    update(user: User) {
        return this.http.put(`${this.config.apiUrl}/users/${user.userid}`, user);
    }

    delete(id: number) {
        return this.http.delete(`${this.config.apiUrl}/users/${id}`);
    }

    listByEntity(request: UsersByEntityRequest) {
        return this.http.post<any>(`${this.config.apiUrl}/users/usersByEntity`, request);
    }

    listByEvent(request: UsersByEventRequest) {
        return this.http.post<any>(`${this.config.apiUrl}/users/listByEvent`, request);
    }

    alerts(request: AlertsRequest) {
        return this.http.post<any>(`${this.config.apiUrl}/alerts`, request);
    }

    // Security section
    userGroups(request: UserGroupsRequest) {
        return this.http.post<any>(`${this.config.apiUrl}/users/userGroups`, request);
    }

    userGroupsSave(request: UserGroupsSaveRequest) {
        return this.http.post<any>(`${this.config.apiUrl}/users/userGroupsSave`, request);
    }

    usersByGroup(request: UsersByGroupRequest) {
        return this.http.post<any>(`${this.config.apiUrl}/users/usersByGroup`, request);
    }

    usersByGroupSave(request: UsersByGroupSaveRequest) {
        return this.http.post<any>(`${this.config.apiUrl}/users/usersByGroupSave`, request);
    }

    validate(request: UserValidateRequest) {
        return this.http.post<any>(`${this.config.apiUrl}/users/validate`, request);
    }
}
