import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './services/authentication/auth.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  providers: [IconSetService],
})
export class AppComponent implements OnInit {
  
  innerWidth: number;
  innerHeight: number;

  public mediaBreakpoint$: BehaviorSubject<string> = new BehaviorSubject(null);
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;

    // console.log('window:resize', event);
    //this._setScreenWidth(window.innerWidth);
    this._setMediaBreakpoint(window.innerWidth);
  }
  
  constructor(
    private router: Router,
    public iconSet: IconSetService,
    private authService: AuthService
  ) {
    //this._setMediaBreakpoint(window.innerWidth);
    // iconSet singleton
    iconSet.icons = { ...freeSet };
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this._setMediaBreakpoint(window.innerWidth);
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  private _setMediaBreakpoint(width: number): void {
    if (width < 576) {
      this.mediaBreakpoint$.next('xs');
    } else if (width >= 576 && width < 768) {
      this.mediaBreakpoint$.next('sm');
    } else if (width >= 768 && width < 992) {
      this.mediaBreakpoint$.next('md');
    } else if (width >= 992 && width < 1200) {
      this.mediaBreakpoint$.next('lg');
    } else if (width >= 1200 && width < 1600) {
      this.mediaBreakpoint$.next('xl');
    } else {
      this.mediaBreakpoint$.next('xxl');
    }
    this.authService.setMediaBreakpoint(this.mediaBreakpoint$.value);
  }

}
