import { Component, Inject, NgModule, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html'
})
export class ConfirmationComponent {

  text: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { displayText: string, titleText: string, btnType: string, confirmBtnText: string, showCancel: boolean },
  public dialogRef: MatDialogRef<ConfirmationComponent>) { 
	  if (!data.displayText) {
		  data.displayText = "Some Body Text";
	  }
	  if (!data.titleText) {
		  data.titleText = "Title Text";
	  }
	  if (!data.btnType) {
		data.btnType = "btn-success";
	  }
	  if (!data.confirmBtnText) {
		  data.confirmBtnText = "Ok";
	  }
	  if (typeof(data.showCancel) == 'undefined') {
		  data.showCancel = true;
	  }
  }

  close(selection: boolean) {
	  this.dialogRef.close({data: selection });
  }

}
