import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { ReportRequest } from '../../../services/reporting/models/ReportRequest';
import { Event } from '../../../services/events/models/Event';
import { ReportService } from '../../../services/reporting/report.service';
import { first } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss']
})
export class ReportDialogComponent implements OnInit {
  reportRequest: ReportRequest;
  loading = false;
  pdfContent: any;
  pdfFilePath = "";
  height:string;

  text: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {eventid: number, userid: number, width:number, height:number},
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    private reportService: ReportService,
    private sanitizer: DomSanitizer,
  ){ }


  ngOnInit(): void {
    console.log(this.data);
    this.generateReport(this.data.userid,this.data.eventid);
    this.height = (this.data.height - 100).toString()+'px';
  }

  generateReport(userid,eventid) {
    console.log(this.data);
    this.loading = true;
    this.reportRequest = new ReportRequest();
    this.reportRequest.reportname = "eventStatementReport";

    this.reportRequest.parameters = "eventid=" + eventid.toString()+"|organisationid="+userid.toString();
    this.reportRequest.format = "pdf";

    // console.log('generateReport', this.reportRequest);
    this.reportService.ssrsReport(this.reportRequest)
      .pipe(first())
      .subscribe(
        data => {
          // console.log('report', data);

          // var file = new Blob([data.data], { type: 'application/pdf' });
          // var fileURL = URL.createObjectURL(file);
          this.pdfContent = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + data.data);
          this.pdfFilePath = 'data:application/pdf;base64,' + data.data;
          // this.pdfContent = fileURL;

          // var blob = this.base64ToBlob(data.data, 'application/pdf');
          // var objectUrl = URL.createObjectURL(blob);
          // window.open(objectUrl);

          this.loading = false;
        },
        error => {
          console.log('report error', error);
          this.loading = false;
        });
  }

  close(selection: boolean) {
	  this.dialogRef.close({data: selection });
  }

}
