export class Transaction {
    id: number;
    type: number;
    date:Date;
    detail:string;
    amount:number;
    description:string;
    reference:string;

    userid:number;
    createdbyid:number;
    eventid:number;
    paymentid:string;
    purchaseid:number;
  }