import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { ForgotPwdRequest } from '../../services/admin/user/models/ForgotPwdRequest';
import { User } from '../../services/admin/user/models/User';
import { AuthService } from '../../services/authentication/auth.service';
import { ImpersonateRequest } from '../../services/authentication/models/ImpersonateRequest';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  username: string;
  password: string;
  view: string = 'login';
  currentUser: User;
  canImpersonate = false;
  users: Array<User>;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService) { }

  ngOnInit() {
    
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      impersonationUserid: [-1]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  changeView(view: string): void {
    this.view = view;

    switch (view) {
      case "login":
        this.f.password.setValidators(Validators.required);
        break;
      case "forgot":
        this.f.password.clearValidators();
        break;
      case "ticket":
        this.router.navigate(['/event-display']);
        break;
    }
    this.f.password.updateValueAndValidity();

  }

  impersonate() {
    this.loading = true;
    if (this.f['impersonationUserid'].value > 0) {
      let request = new ImpersonateRequest();
      request.adminid = this.currentUser.userid;
      request.userid = this.f['impersonationUserid'].value;

      this.authService.impersonate(request)
      .subscribe(data => {
        if (data.success) {
          this.authService.UpdateCurrentUser(data.data.user);
          this.authService.UpdateGroups(data.data.groups);
          // this.authService.UpdateContract(data.data.contract);
          this.router.navigate(['/dashboard']);
        } else {
          this.loading = false;
          this.error = data.message;
        }
      })
    }
  }

  quick(option: number) {
    if (environment.env == 'prod') return;
    if (option == 0) {
      this.loginForm.get('username').setValue('admin');
      this.loginForm.get('password').setValue('@Test123');
    } else if (option == 1) {
      this.loginForm.get('username').setValue('annavniekerk@gmail.com');
      this.loginForm.get('password').setValue('@Test123');
    } else if (option == 2) {
      this.loginForm.get('username').setValue('pietervniekerk@gmail.com');
      this.loginForm.get('password').setValue('@Test123');
    } else if (option == 3) {
      this.loginForm.get('username').setValue('admin@gre.co.za');
      this.loginForm.get('password').setValue('@Test123');
    }
    
    this.onSubmit();
  }

  toRegister() {
	this.router.navigate(['/register']);
  }

  onSubmit() {
      this.submitted = true;

      console.log('this.loginForm.invalid', this.loginForm.invalid);
      console.log('this.loginForm', this.loginForm);
      // stop here if form is invalid
      if (this.loginForm.invalid) {
          return;
      }

      if (this.view == 'login')
      {
        this.loading = true;
        this.authService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    console.log('data', data);
                    if (!data.success) {
                      this.loading = false;
                      this.error = data.message;
                    }
                    else{

                      this.currentUser = data.data.user;
                      if (data.data.groups[0].name != 'imp_admin') {
                        this.router.navigate(['/dashboard']);
                      } else {
                        this.authService.impersonationUsers()
                        .subscribe(
                          data => {
                            if (data.success) {
                              this.users = data.data;
                              this.users.forEach(u => {
                                if(u.organisation == null || u.organisation == '')
                                  u.name = u.lastname+', '+u.firstname;
                                else
                                  u.name = u.organisation;
                              });
                              this.users.sort((a, b) => a.name.localeCompare(b.name));
                              this.loading = false;
                              this.canImpersonate = true;
                            } else {
                              this.error = data.message;
                              this.loading = false;
                            }
                          }
                        )
                      }
                    }
                      // this.router.navigate([this.returnUrl]);
                      // this.router.navigate(['/dashboard']);
                },
                error => {
                    this.error = error.message;
                    this.loading = false;
                });
      } else {
        
        this.loading = true;
        let request = new ForgotPwdRequest();
        request.username = this.f.username.value;
        this.authService.forgotPwd(request)
            .pipe(first())
            .subscribe(
                data => {
                    console.log('data', data);
                    if (!data.success) {
                      this.loading = false;
                      this.error = data.message;
                    }
                    else {
                      this.loading = false;  
                      this.toastr.success('Password request submitted successfully. Be on the lookout for a mail...', 'Forgot Password', {timeOut: 4000, positionClass: 'toast-bottom-right'});
                      this.view = 'login';
                    }
                },
                error => {
                    this.error = error.message;
                    this.loading = false;
                });
      }

  }

}
