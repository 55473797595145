import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { User } from '../admin/user/models/User';
import { JwtService } from '../../jwt-service';
import { ForgotPwdRequest } from '../admin/user/models/ForgotPwdRequest';
import { Group } from '../admin/group/models/Group';
import { add } from 'date-fns';
import { ChangePwdRequest } from './models/ChangePwdRequest';
import { ImpersonateRequest } from './models/ImpersonateRequest';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private currentUserSubject: BehaviorSubject<User>;
    private currentEventSubject: BehaviorSubject<Number>;
    public currentUser: Observable<User> = null;
    public eventid: Observable<Number> = null;

    private currentUserGroupsSubject: BehaviorSubject<Array<Group>>;
    public currentUserGroups: Observable<Array<Group>> = null;

    public config = environment;
    
    public mediaBreakpoint$: BehaviorSubject<string> = new BehaviorSubject(null);
    mediaBreakpoint = this.mediaBreakpoint$.asObservable();

    error: string = '';
    private errorSource = new BehaviorSubject<string>(this.error);
    error$: Observable<string> = this.errorSource.asObservable();

    constructor(
        private http: HttpClient, 
        private jwtService: JwtService) {
            
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.currentEventSubject = new BehaviorSubject<Number>(JSON.parse(localStorage.getItem('currentEvent')));
        this.eventid = this.currentEventSubject.asObservable();

        this.currentUserGroupsSubject = new BehaviorSubject<Array<Group>>(JSON.parse(localStorage.getItem('currentUserGroups')));
        this.currentUserGroups = this.currentUserGroupsSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${this.config.apiUrl}/auth/login`, { username, password })
            .pipe(map(response => {
                // console.log('user res', response);
                // login successful if there's a jwt token in the response
                if (response.data.user && response.data.groups[0].name != 'imp_admin') { // && response.data.user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(response.data.user));
                    localStorage.setItem('token', JSON.stringify(response.data.user.token));
                    localStorage.setItem('currentUserGroups', JSON.stringify(response.data.groups));

                    this.currentUserSubject.next(response.data.user);
                    this.currentUserGroupsSubject.next(response.data.groups);
                }

                return response;
            }));
    }

    currentevent(id: number){
        localStorage.setItem('currentEvent', JSON.stringify(id));
        this.eventid = of(id);
    }

    impersonate(request: ImpersonateRequest) {
        return this.http.post<any>(`${this.config.apiUrl}/auth/impersonate`, request);
    }

    impersonationUsers() {
        return this.http.get<any>(`${this.config.apiUrl}/auth/impersonate/users`);
    }
    
    public UpdateCurrentUser(user: User): void {
        this.currentUserSubject.next(user);
        localStorage.setItem('currentUser', JSON.stringify(user));
    }

    public UpdateGroups(groups: Array<Group>): void {
        this.currentUserGroupsSubject.next(groups);
        localStorage.setItem('currentUserGroups', JSON.stringify(groups));
        // console.log(groups);
    }

	register(organisation: string, mnemonic: string, firstname: string, lastname: string, email: string, mobilenumber: string, address1: string, address2: string, town: number, postcode: string, districtid: number) {
		var request = { 
			"organisation": organisation,
			"mnemonic": mnemonic,
			"firstname": firstname,
			"lastname": lastname,
			"username": email,
			"cityid": town,
			"code": postcode,
			"districtid": districtid,
			"mobile": mobilenumber,
			"physical1": address1,
			"physical2": address2
		}
        return this.http.post<any>(`${this.config.apiUrl}/auth/register`, request)
            .pipe(map(response => {
                // console.log('user res', response);
                // login successful if there's a jwt token in the response
                if (response.data.user) { // && response.data.user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(response.data.user));
                    localStorage.setItem('token', JSON.stringify(response.data.user.token));
                    localStorage.setItem('currentUserGroups', JSON.stringify(response.data.groups));

                    this.currentUserSubject.next(response.data.user);
                    this.currentUserGroupsSubject.next(response.data.groups);
                }

                return response;
            }));
    }
    
    changePwd(changePwdRequest: ChangePwdRequest) {
        return this.http.post<any>(`${this.config.apiUrl}/auth/changePwd`, changePwdRequest);
    }

    forgotPwd(request: ForgotPwdRequest) {
        return this.http.post<any>(`${this.config.apiUrl}/auth/forgotPwd`, request);
    }

    public getToken(): string {
        return localStorage.getItem('token');
    }

    public isAuthenticated(): boolean {
      // get the token
      const token = this.getToken();

    //   console.log('jwtService', this.jwtService.DecodeToken(token))
      // return a boolean reflecting 
      // whether or not the token is expired
      return true; // tokenNotExpired(null, token);
    }

    logout() {
        // remove user from local storage to log user out
        // console.log('logout')
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        sessionStorage.clear();
        // console.log('logout done')
    }

    public setMediaBreakpoint(value: string): void {
        this.mediaBreakpoint$.next(value);
    }

    public currentError(): string {
        return this.error;
    }

    public setError(value: string): void {
        this.errorSource.next(value);
    }

    public clearError(): void {
        this.errorSource.next('');
    }

    yocoPay(token: string, amount: number) {
        return this.http.post<any>(`${this.config.apiUrl}/yoco/pay`, { AmountInCents: amount, Token: token});
    }
}