import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/authentication/auth.service";

@Injectable({
    providedIn: "root"
})
export class LoginGuard implements CanActivate {
  constructor(private authService: AuthService,
    private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean|UrlTree {
    //console.log(state.url);
    if (state.url == '/login' || state.url == '/register') {
      return !this.authService.currentUserValue ? true : this.router.parseUrl('/dashboard');
    } else {
      return this.authService.currentUserValue ? true : this.router.parseUrl('/login');
    }
  }
}