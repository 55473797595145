import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrderByPipe } from '../../pipes/order-by-pipe';
import { UserFilterPipe } from '../../pipes/user-filter.pipe';
import { StudentFilterPipe } from '../../pipes/student-filter.pipe';

@NgModule({
  declarations: [
    OrderByPipe,
    UserFilterPipe,
    StudentFilterPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    OrderByPipe,
    UserFilterPipe,
    StudentFilterPipe
  ]
})
export class AppPipesModuleModule { }
