import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Section } from "../models/section/Section";
import { TicketingEvent } from "../models/ticket/ticketingevent";
import { BuyerInfo } from "../models/ticket/BuyerInfo";
import { TicketingEventExt } from "../models/ticket/TicketingEventExt";

@Injectable({ providedIn: 'root' })
export class TicketService {
    public config = environment;

    constructor(private http: HttpClient) { }

    getTicketingEvent(id: Number) {
        return this.http.get<any>(`${this.config.apiUrl}/ticket/GetTicketingEvent/${id}`);
    }

    getOpenTicketingEvent() {
        return this.http.get<any>(`${this.config.apiUrl}/ticket/GetOpenTicketingEvent`);
    }

    ticketingEventSave(request: TicketingEvent) {
        console.log('save',request);
        return this.http.post<any>(`${this.config.apiUrl}/ticket/ticketingEventSave`, request);
    }
    purchaseHistory(request: BuyerInfo) {
        return this.http.post<any>(`${this.config.apiUrl}/ticket/purchaseHistory`, request);
    }

    ticketingEventUpdate(request: TicketingEvent) {
        console.log('update', request);

        return this.http.put<any>(`${this.config.apiUrl}/ticket/ticketingEventUpdate`, request);
    }

    DeleteTicketingEvent(id: Number) {
        return this.http.delete<any>(`${this.config.apiUrl}/ticket/DeleteTicketingEvent/${id}`);
    } 
    uploadticket(eventid: string, file: FormData) {
        const req = new XMLHttpRequest();
        req.open("POST", `${this.config.apiUrl}/ticket/upload/${eventid}`);
        //req.setRequestHeader("Content-Type", "multipart/mixed")
        req.send(file);
        return req;

    }
}
