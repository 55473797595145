import { Purchase } from "../ticket/Purchase";

export class YocoRedirectRequest {
    amount: number;
    currency: string;
    cancelUrl: string;
    successUrl: string;
    from:string;
    userid: number;
    eventid:number;
    purchaseid:number;
  }