import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Group } from '../../services/admin/group/models/Group';
import { AlertsRequest } from '../../services/admin/user/models/AlertsRequest';
import { User } from '../../services/admin/user/models/User';
import { UserService } from '../../services/admin/user/user.service';
import { AuthService } from '../../services/authentication/auth.service';
import { LoaderService } from '../../services/general/loader.service';

import { navItems } from '../../_nav';
import { limitedNavItems } from '../../__nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  currentUser: User;
  currentUserGroups: Array<Group>;
  _loading = false;
  loading = false;
  error: string;
  mediaBreakpoint: string = "";
  alerts: Array<any> = [];
  _error: string;

  permissions: Array<any> = [
    {
      groupid: 1, group: 'Administrator',
      allowedNavItems: [
        { name: 'Dashboard' },
        { name: 'Administration' }, { name: 'Events' }, { name: 'Students' }, { name: 'Disciplines' },
        { name: 'Sections' }, { name: 'Levels' }, { name: 'Types' }, { name: 'Age Groups' },
        { name: 'Districts' }, { name: 'Organisations-Users' },
        { name: 'Security' }, { name: 'Group(s)' }, { name: 'Group User(s)' },
        { name: 'Financial' }
      ]
    },
    {
      groupid: 2, group: 'Super User',
      allowedNavItems: [
        { name: 'Dashboard' },
        { name: 'Administration' }, { name: 'Events' }, { name: 'Students' },
        { name: 'Disciplines' }, { name: 'Sections' }, { name: 'Levels' },
        { name: 'Types' }, { name: 'Age Groups' }, { name: 'Districts' }, { name: 'Organisations-Users' },
        { name: 'Receipt' }, { name: 'Transaction' }
      ]
    },
    {
      groupid: 3, group: 'User',
      allowedNavItems: [{ name: 'Dashboard' }, { name: 'Administration' }, { name: 'Students' }
      ]
    },
    {
      groupid: 4, group: 'Reviewer',
      allowedNavItems: [{ name: 'Dashboard' }]
    },
    {
      groupid: 6, group: 'Reviewer',
      allowedNavItems: [{ name: 'Dashboard' }]
    }
  ];

  constructor(private route: ActivatedRoute,
    private router: Router,
    private loaderService: LoaderService,
    private authService: AuthService,
    private userService: UserService) {
    // // console.log('dashboard constructor');
    this.authService.currentUser.subscribe(x => this.currentUser = x);
    this.authService.currentUserGroups.subscribe(x => this.currentUserGroups = x);
    this.loaderService.isLoading.subscribe(l => { this._loading = l; });
  }
  ngOnInit(): void {
    // console.log('default layout', this.currentUser);
    this.authService.mediaBreakpoint.subscribe((data) => {
      this.mediaBreakpoint = data
    });

    this.authService.error$.subscribe((data) => {
      this._error = data
    });

    this.fetchAlerts();

    // console.log('user', this.currentUser);
    // console.log('user', this.currentUserGroups);

    // reset menu
    navItems.forEach(element => {
      if (element.attributes != undefined)
        delete element.attributes.hidden;

      if (element.children) {
        element.children.forEach(child => {
          if (child.attributes != undefined)
            delete child.attributes.hidden;
        });
      }
    });

    if (this.currentUserGroups != null) {
      navItems.forEach(element => {
        if (!this.lookupPermission(element)) {
          element.attributes = { 'hidden': true };
        }
        if (element.children) {
          element.children.forEach(child => {
            if (!this.lookupPermission(child)) {
              child.attributes = { 'hidden': true };
            }
          });
        }
      });
    }
  }

  lookupPermission(_navItem: any): boolean {
    let allow = false;
    // console.log('allowedGroups', this.currentUserGroups);
    // console.log('_navItem', _navItem);

    // check for Administrator group
    let group = this.currentUserGroups.find(p => p.groupid == 1);
    if (group != null) {
      // console.log('Administrator group found')
      // console.log('User group found')
      let permission = this.permissions.find(p => p.groupid == 1);
      // console.log('permission', permission);
      if (permission != null) {
        let navItem = permission.allowedNavItems.find(p => p.name == _navItem.name);
        // console.log('navItem', navItem);
        allow = navItem != null;
      }
    }
    else {
      // check for SuperUser group
      let group = this.currentUserGroups.find(p => p.groupid == 2);
      if (group != null) {
        // console.log('Super user group found')
        // console.log('User group found')
        let permission = this.permissions.find(p => p.groupid == 2);
        // console.log('permission', permission);
        if (permission != null) {
          let navItem = permission.allowedNavItems.find(p => p.name == _navItem.name);
          // console.log('navItem', navItem);
          allow = navItem != null;
        }
      }
      else {
        // check for User group
        let group = this.currentUserGroups.find(p => p.groupid == 3);
        if (group != null) {
          // console.log('User group found')
          let permission = this.permissions.find(p => p.groupid == 3);
          // console.log('permission', permission);
          if (permission != null) {
            let navItem = permission.allowedNavItems.find(p => p.name == _navItem.name);
            // console.log('navItem', navItem);
            allow = navItem != null;
            // expand Administration menu if user is part of User group
            if (_navItem.name == 'Administration')
              _navItem.url = '';
          }
        }
        else {
          // check for User group
          let group = this.currentUserGroups.find(p => p.groupid == 4);
          if (group != null) {
            // console.log('User group found')
            let permission = this.permissions.find(p => p.groupid == 4);
            // console.log('permission', permission);
            if (permission != null) {
              let navItem = permission.allowedNavItems.find(p => p.name == _navItem.name);
              // console.log('navItem', navItem);
              allow = navItem != null;
              // expand Administration menu if user is part of User group
              if (_navItem.name == 'Administration')
                _navItem.url = '';
            }
          }
        }
      }

    }
    return allow;
  }

  fetchAlerts(): void {
    this.loading = true;
    let request = new AlertsRequest();
    request.userid = this.currentUser.userid;
    this.userService.alerts(request)
      .pipe(first())
      .subscribe(
        data => {
          // // console.log('fetchAlerts data', data);
          if (!data.success) {
            this.error = data.message;
          }
          else {
            this.alerts = data.data;
          }
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  clear(): void {
    this.authService.clearError();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  public sidebarMinimized = false;
  public navItems = navItems;
  public limitedNavItems = limitedNavItems;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  entityProfile(): void {
    this.router.navigate(['/entity']);
  }

  userProfile(): void {
    this.router.navigate(['/user']);
  }

  selectAlert(alert: any): void {
    // // console.log('alert', alert);
    switch (alert.object) {
      case "user":
        this.router.navigate(['admin/users']);
        break;
      case "slot":
        this.router.navigate(['schedule/sessions']);
        break;
      case "billingentity":
        this.router.navigate(['admin/billing-entities']);
        break;
      case "quarter":
        this.router.navigate(['admin/quarters']);
        break;
    }
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
